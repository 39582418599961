<template>
  <!-- <nav class="flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-2 text-sm">
              <li>
                <div class="flex items-center">
                  <a href="#" class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-800">
                    <fa-icon icon="house" />
                  </a>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <fa-icon icon="chevron-right" class="flex-shrink-0 mx-1 text-gray-500 fa-sm" />

                  <div class="relative z-10">
                    <div>
                      <button
                        id="headlessui-menu-button-13"
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        class="ml-2 font-medium text-gray-500 hover:text-gray-800"
                      >
                        ..
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <fa-icon icon="chevron-right" class="flex-shrink-0 mx-1 text-gray-500 fa-sm" />

                  <a
                    href="#"
                    class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-800"
                  >November</a>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <fa-icon icon="chevron-right" class="flex-shrink-0 mx-1 text-gray-500 fa-sm" />

                  <div class="relative z-10">
                    <div>
                      <button
                        id="headlessui-menu-button-15"
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        class="ml-2 font-medium text-gray-500 hover:text-gray-800"
                      >
                        WIP 2
                        <fa-icon icon="caret-down" class="flex-shrink-0 mx-1 text-gray-500 fa-sm" />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
  </nav>-->

  <div class="flex-1">
    <div class="flex px-4 text-xs text-gray-700 breadcrumb dark:text-gray-300">
      <template>
        <a
          href="#"
          class="active section"
          @click.prevent="viewRootFolder"
        >
          <fa-icon
            icon="house"
            class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-800"
          />
        </a>
      </template>

      <template v-for="folder, index in ancestors">
        <template v-if="folder.parent">
          <div class="mx-2 text-gray-400 opacity-75 divider">
            /
          </div>
          <a
            href="#"
            class="text-blue-500 section"
            @click.prevent="viewFolder(folder.id)"
          >{{ folder.name }}</a>
        </template>
      </template>

      <template v-if="!isRoot">
        <template v-if="asset">
          <div class="mx-2 text-gray-400 opacity-75 divider">
            /
          </div>
          <span class="font-bold text-blue-500 select-none active section">{{ asset.name }}</span>
        </template>
        <template v-else>
          <div class="mx-2 text-gray-400 opacity-75 divider">
            /
          </div>
          <a
            v-if="activeFolder"
            href="#"
            class="text-blue-500 active section"
            :class="{ 'font-bold': !asset }"
            @click.prevent="viewFolder(activeFolder.id)"
          >{{ name }}</a>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
export default {

  computed: {

    project () {
      return this.$store.getters['project/project']
    },

    projectRoot () {
      return '/p/' + this.slug
    },

    title () {
      if (this.project) return this.project.title
      return '..loading..'
    },

    slug () {
      if (this.project) return this.project.slug
      return ''
    },

    activeFolder: function () {
      return this.$store.getters['assets/activeSet'](this.project.id)
    },

    isRoot () {
      if (this.activeFolder) return this.activeFolder.parent === null
      return false
    },

    name () {
      if (this.activeFolder) return this.activeFolder.name
      return ''
    },

    ancestors () {
      if (this.asset) return this.$store.getters['assets/getTreeForFolderId'](this.asset.parent_id)
      if (this.activeFolder && !this.isRoot) return this.$store.getters['assets/getTreeForFolderId'](this.activeFolder.parent)
      return []
    },

    folders () {
      return this.$store.getters['assets/loadedSets']
    },

    toRoot () {
      return {
        name: 'project_content',
        params: {
          projectId: this.projectSlug
        }
      }
    },

    projectSlug () {
      return this.project.slug
    },

    rawFolders () {
      return this.$store.getters['assets/rawData']
    },

    routerUUID () {
      return this.$route.params.uuid
    },

    asset () {
      if (this.$route.name !== 'content_view' && this.$route.name !== 'editor-sequencer') return null
      return this.$store.getters['assets/getAssetById'](this.routerUUID)
    }
  },

  methods: {

    toFolder (folderId) {
      return {
        name: 'content_folder',
        params: {
          uuid: folderId
        }
      }
    },

    viewFolder (set) {
      let asset = {
        id: set,
        type: 'folder'
      }
      this.$emit('select-asset', asset)
    },

    viewRootFolder (set) {
      let asset = {
        type: 'root'
      }
      this.$emit('select-asset', asset)
    }
  }
}

</script>
